import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExportExcel from "./ExportExcel";


function Tabela({ dados }) {
  const [info, setInfo] = useState();

  useEffect(() => {
    setInfo(dados);
   
  }, [dados]);

  function onMaterialHandler(material){
    var total = 0;
    for(var i = 0; i < material.length ; i++){
      total = total + material[i].columns.vlr;
    }
    return total.toFixed(2);
  }

  function onMaterialNameHandler(material){
    if (material != null) {
      return material.label;  
    }
  }

  function onMaterialVlrHandler(material){
    if (material != null) {
      if(material.valor_unitario != null){
        
      return material.valor_unitario.toFixed(2);
      } else {
        return "";
      }
      //console.log(material);
    }
  }

  function onMaterialUndHandler(material){
    if (material != null) {
      if(material.unidade != null){
        return material.unidade; 
      } else{
        return "";
      }
          
    }
  }

  function onComplexo(issue){
    if(issue.fields.customfield_10065 != null) {
      return issue.fields.customfield_10065.value + " / " + getEdificio(issue.fields);
    }
  }

  function especialidadeHandler(issue){
    if(issue.fields.customfield_10229 != null) {
      return issue.fields.customfield_10229.value;
    } else {
      return "";
    }
  }

  function getEdificio(complexo){
    //customfield_10081 -> SGON
    //customfield_10079 -> PCDF
    //customfield_10078 -> DASG/DITRAN
    //customfield_10080 -> REGIONAL NORTE
    //customfield_10082 -> UNIDADES DIVERSAS
    //console.log(complexo);

      switch (complexo.customfield_10065.value){

      
        case "COMPLEXO PCDF": {
          if(complexo.customfield_10079 == null){

          } else {
            return complexo.customfield_10079.value;
          }
         
          break;
        }
  
        case "COMPLEXO SGON": {
          if(complexo.customfield_10081 == null){

          }else{
            return complexo.customfield_10081.value;
          }
          break;
        }
  
        case "COMPLEXO DASG/DITRAN": {
          if(complexo.customfield_10078 ==null){
          }else{
            return complexo.customfield_10078.value;
          }
          break;
        }
  
        case "COMPLEXO REGIONAL NORTE": {
          if(complexo.customfield_10080 ==null){
          }else{
            
            return complexo.customfield_10080.value;
          }
          break;
        }
  
        case "UNIDADES DIVERSAS": {
          if(complexo.customfield_10082 ==null){
          }else{
            return complexo.customfield_10082.value;
          }
            break;
        }
  
        default: return "";        
      }
  }

  function dateHandler(date) {

    if(date != null){
      var newDate = new Date(date);
      return newDate.toLocaleDateString();
    }

  }


    
  return (
    <div>
      <ExportExcel fileName={"Relatorio de Materiais"} table={document.getElementsByTagName('table')[0]}/>
      <TableContainer component={Paper} elevation={20}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="">Data de Criação</TableCell>
              <TableCell align="">Data de Resolução</TableCell>
              <TableCell align="">Nº OS</TableCell>
              <TableCell align="">Local</TableCell>
              <TableCell align="">Resumo</TableCell>
              <TableCell align="">Material</TableCell>
              <TableCell align="">Qtd. Material</TableCell>
              <TableCell align="">UND. Material</TableCell>
              <TableCell>Vlr. Unit.</TableCell>
              <TableCell>Vlr. Total</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Especialidade</TableCell>
              <TableCell align="right">Tipo</TableCell>
            </TableRow>
          </TableHead>

          
          {dados.map((issue) =>
              <TableBody>
                {issue.material.map((m) =>     
                    <TableRow>
                      <TableCell>{dateHandler(issue.fields.created)}</TableCell>
                      <TableCell>{dateHandler(issue.fields.resolutiondate)}</TableCell>
                      <TableCell>{issue.key}</TableCell>
                      <TableCell>{onComplexo(issue)}</TableCell>
                      <TableCell>{issue.fields.summary}</TableCell>
                      <TableCell>{onMaterialNameHandler(m.columns.materiais)}</TableCell>
                      <TableCell>{m.columns.qtd}</TableCell>
                      <TableCell>{onMaterialUndHandler(m.columns.materiais)}</TableCell>
                      <TableCell>{"R$ " + onMaterialVlrHandler(m.columns.materiais)}</TableCell>
                      <TableCell>{"R$ " + (onMaterialVlrHandler(m.columns.materiais) * m.columns.qtd).toFixed(2)}</TableCell>
                      <TableCell align="right">{issue.fields.status.name}</TableCell>
                      <TableCell align="right">{especialidadeHandler(issue)}</TableCell>
                      <TableCell align="right">{issue.fields.issuetype.name}</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            )}
         
        </Table>
      </TableContainer>
    </div>
  );
}

export default Tabela;
